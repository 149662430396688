export const apiEndpoint = {
    data() {
      return {
        VUE_APP_API_URL: "https://uat-sicip-bee.tappware.com/",
        VUE_APP_TRANCHE_LIST_API: "configurations/tranche/list",
        VUE_APP_PENDING_TRAINER_LIST_API: "entity/trainer/pending-trainers",
        VUE_APP_TMS:'https://tms.sicip.gov.bd',
        VUE_APP_DOPTOR:'https://doptor.sicip.gov.bd',
        VUE_APP_TRAINER_UPDATE_API: "entity/trainer/update",
        VUE_APP_GET_ENTITY_LIST_API:'entity/list',
        VUE_APP_ASSESSOR_UPDATE_API: "entity/assessor/update",
        VUE_APP_TRAINER_SAVE_API: "entity/trainer/save",
        VUE_APP_TRAINING_CALENDAR_DETAILS_API:'training/calendardetails',
        VUE_APP_TRAINER_APPROVE_API: "entity/trainer/approve/",
        VUE_APP_COURSE_APPROVE_API: "course/approve/",
        VUE_APP_COURSE_REJECT_API: "course/reject/",
        VUE_APP_TRAINER_REJECT_API: "entity/trainer/reject/",
        VUE_APP_COURSE_CHAPTER_SHOW_API: "course/chapter-show/",
        VUE_APP_COURSE_CHAPTER_SAVE_API: "course/chapter-save",
        VUE_APP_COURSE_CHAPTER_DELETE_API: "course/chapter-delete/",
        VUE_APP_UNIT_OF_COMPETENCY_SHOW_API: "course/unit-of-competency-show/",
        VUE_APP_UNIT_OF_COMPETENCY_SAVE_API: "course/unit-of-competency-save",
        VUE_APP_UNIT_OF_COMPETENCY_DELETE_API: "course/unit-of-competency-delete/",
        VUE_APP_UNIT_OF_COMPETENCY_List_API: "course/unit-of-competency-list",
        VUE_APP_COURSE_CHAPTER_List_API: "course/chapter-list",
        VUE_APP_PENDING_ASSESSOR_LIST_API: "entity/assessor/pending-assessors",
        VUE_APP_ASSESSOR_UPDATE_API: "entity/assessor/update",
        VUE_APP_ASSESSOR_APPROVE_API: "entity/assessor/approve/",
        VUE_APP_ASSESSOR_REJECT_API: "entity/assessor/reject/",
        VUE_APP_MENU_WITH_ACTION_API: "menu/list",
        VUE_APP_MENU_ACTION_PERMISSION_API: "menuactionrole/list/",
        VUE_APP_MENU_ACTION_SAVE_API: "menuactionrole/store",
        VUE_APP_ENTITY_TYPE_API: "configurations/entity_type/show/",
        VUE_APP_GET_BATCH_TERM_API:'batch/term_info',
        VUE_APP_BATCH_LIST_API:'batch/list',
        VUE_APP_COURSE_SEARCH_LIST_API:'course/list',
        VUE_APP_INSTITUTE_LIST_API: "institute/list",
        VUE_APP_COURSE_SEARCH_LIST_API: "course/list",
        VUE_APP_SINGLE_COURSE_SEARCH_LIST_API: "course/show/",
        VUE_APP_COURSE_DETAILS_API: "course/show/",
        VUE_APP_PENDING_COURSE_TARGET_LIST_API: "institutetarget/pending-list",
        VUE_APP_PENDING_LOG_TARGET_LIST_API: "course/log-target",
        VUE_APP_COURSE_MAP_TRAINER_API: "entity/trainer/map_course_trainer",
        VUE_APP_ENTITY_TYPE_ROLE_UPDATE: "entity_type_role/role/update",
        VUE_APP_ENTITY_TYPE_ROLE_SAVE: "entity_type_role/role/save",
        VUE_APP_ENTITY_TYPE_ROLE_GET_BY_ID: "entity_type_role/role/getsinglerole",
        VUE_APP_TRAINING_CALENDAR_DETAILS_API:'training/calendardetails',
        VUE_APP_GET_BATCH_TERM_API:'batch/term_info',
        VUE_APP_BATCH_LIST_API:'batch/list',
  
      }
    },
    methods: {
      numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    }
  }